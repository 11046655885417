<script>
import AppButton from "@/components/atoms/AppButton.vue";
import ModularCard from "@/components/molecules/ModularCard.vue";

export default {
  name: "CandidateErrorTemplate",
  components: { AppButton, ModularCard },
  data() {
    return {
      linkExpiredCard: {
        0: {
          type: "title",
          title: "Erro ao Finalizar Cadastro.",
        },
        1: {
          type: "image",
          image: "error",
        },
        2: {
          subtitle: "Ocorreu um erro ao tentar salvar suas informações.",
          type: "subtitle",
        },
        3: {
          type: "subtitle",
          subtitle:
            "Por favor, verifique sua conexão e tente novamente em alguns minutos.",
        },
        4: {
          type: "buttons",
        },
      },
    };
  },
  methods: {
    emit(payload) {
      this.$emit("click", payload);
    },
  },
};
</script>

<template>
  <span class="section-container message-template" style="padding: 16px">
    <modular-card
      :cardModules="linkExpiredCard"
      customStyle="min-height: 100% !important; justify-content: flex-start; gap: 32px;"
    >
      <span style="display: flex; flex-direction: column; gap: 8px">
        <app-button
          name="go-home"
          text="Voltar para o Website"
          type="new-return"
          icon="mdi-open-in-new"
          customClass="independent-button"
          @click="emit('goToWebsite')" />
        <app-button
          name="go-back"
          text="Reiniciar Formulário"
          type="new-default"
          icon="mdi-reload"
          customClass="independent-button"
          @click="emit('goBack')"
      /></span>
    </modular-card>
  </span>
</template>

<style lang="scss">
.message-template {
  min-height: calc((var(--vh, 1vh) * 100)) !important;
  display: flex;

  @media screen and (min-height: 800px) {
    max-height: calc((var(--vh, 1vh) * 100)) !important;
    align-items: center !important;
  }
}
</style>
