<script>
import CandidateErrorTemplate from "@/components/templates/CandidateErrorTemplate.vue";

export default {
  name: "CandidateErrorPage",
  components: { CandidateErrorTemplate },
  data() {
    return {};
  },
  methods: {
    click(payload) {
      if (payload === "goToWebsite") {
        window.location.href = "https://www.literato.net";
      }
      if (payload === "goBack") {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<template>
  <candidate-error-template @click="click" />
</template>

<style lang="scss" scoped></style>
